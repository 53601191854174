/* eslint-disable max-len */
import React from 'react';
import { SignalIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import { DateTime } from 'luxon';
import ItemList from '../../../components/ItemsList';
import FilterBar from '../../../components/layout/FilterBar/V2FitlerBar';
import PageHeader from '../../../components/layout/PageHeader';
import SearchBar from '../../../components/layout/SearchBar';
import useIndexFetchClient, { useIndexButtons } from '../../../hooks/useIndexFetchClient';
import TableBody from './Body';
import FilterForm from './FilterForm';
import TableHeader from './Header';
import useMe from '../../../context/CurrentUserContext';
import { useNavigate } from 'react-router-dom';

const now = DateTime.now();
const startDate = now.startOf('day').toISO();
const endDate = now.plus({ days: 1 }).startOf('day').toISO();

const config = {
  clientName: 'logs',
  resourceName: 'Request',
  description: 'A list of all requests that are occuring in your Destination Connections',
  mobileTitle: 'Request',
  icon: <SignalIcon className="h-8 w-8 m-1 text-indigo-700" />,
  indexClientOptions: {
    latentSearchParams: { topic: 'action.processed' },
    searchParams: {
      status: 'success',
      start_date: startDate,
      end_date: endDate,
    },
  },
};

export default function IndexPage() {
  // Fetch Index Page Base Data
  const indexClient = useIndexFetchClient(config);

  const { connections } = useMe();

  // Buttons for Top Bar
  const buttons = [
    { label: 'Filter', icon: AdjustmentsVerticalIcon, onClick: () => indexClient.setFilterOpen(true) },
  ];

  return (
    <div>
      <PageHeader
        description={config.description}
        name={`${config.resourceName} List`}
        buttons={buttons}
        icon={config.icon}
      />
      <FilterBar
        resource={`${config.resourceName}s`}
        indexClient={indexClient}
      >
        <FilterForm indexClient={indexClient} />
      </FilterBar>
      <SearchBar
        name="matcher"
        label="Search Actions"
        indexClient={indexClient}
        placeholder="Search for Request by ID..."
        statusTabConfig={{
          label: 'Filter by Status',
          filterKey: 'status',
          tabs: [
            { name: 'Success', value: 'success' },
            { name: 'Error', value: 'Error' },
          ],
        }}
        dateFilterConfig={{
          defaultStartValue: config.indexClientOptions.searchParams.start_date,
          defaultEndValue: config.indexClientOptions.searchParams.end_date,
        }}
      />
      <ItemList
        indexClient={indexClient}
        body={<TableBody items={indexClient.data} connections={connections} />}
        header={<TableHeader />}
      />
    </div>
  );
}
