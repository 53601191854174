import React from 'react';
import useClient from '../../../hooks/useClient';
import Loading from '../../../components/Loading';
import Autocomplete from '../../../components/forms/Autocomplete';
import ComboBox from '../../../components/forms/ComboBox';
import useMe from '../../../context/CurrentUserContext';
import DateInput from '../../../components/forms/DateInput';
import IndexClientShape from '../../../shapes/IndexClientShape';
import PropTypes from 'prop-types';

export default function FilterForm({ indexClient, hideTopicInput }) {
  const onCloseModal = () => indexClient.setFilterOpen(false);

  const { connections } = useMe();
  const aboutClient = useClient('events/about');
  const { data, isLoading } = aboutClient.list({ pageSize: 200 });
  if (isLoading) return <Loading />;

  return (
    <form onSubmit={indexClient.onSubmit}>
      <div>
        <ComboBox
          form={indexClient.filterForm}
          label="Source Connection"
          name="connection_id"
          options={connections}
          labelKey="name"
          valueKey="id"
          imageKey="icon_url"
        />
      </div>
      <div>
        <ComboBox
          form={indexClient.filterForm}
          label="Status"
          name="status"
          options={[
            { label: 'Pending', value: 'pending' },
            { label: 'Resolved', value: 'resolved' },
            { label: 'Unresolved', value: 'unresolved' },
          ]}
          labelKey="label"
          valueKey="value"
        />
      </div>
      <div>
        <DateInput
          form={indexClient.filterForm}
          type="datetime-local"
          label="Start Date"
          name="start_date"
        />
      </div>
      <div>
        <DateInput
          form={indexClient.filterForm}
          type="datetime-local"
          label="End Date"
          name="end_date"
        />
      </div>
      {
        hideTopicInput
          ? null
          : (
            <Autocomplete
              form={indexClient.filterForm}
              label="Topic"
              name="topic"
              placeholder="Topic..."
              values={data?.topics || []}
            />
          )
      }
      <div className="mt-6 flex justify-space-between">
        <button
          onClick={onCloseModal}
          type="button"
          className="mr-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
          Cancel
        </button>
        <button
          type="submit"
          // disabled={isSubmitting}
          className="ml-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Filter
        </button>
      </div>
    </form>
  );
}

FilterForm.propTypes = {
  indexClient: IndexClientShape.isRequired,
  hideTopicInput: PropTypes.bool,
};
