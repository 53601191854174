/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import FormShape from '../../../../../shapes/FormShape';
import RadioButtonGroup from '../../../../../components/forms/RadioInputGroup';
import ConnectionShape from '../../../../../shapes/ConnectionShape';
import ScopeInputWrapper from './ScopeInputWrapper';
import ApiKeySubForm from './ApiKeySubForm';
import BasicSubForm from './BasicSubForm';
import BearerSubForm from './BearerSubForm';
import OAuthTwoSubForm from './OAuthTwoSubForm';

// const defaults = {
//   api_key: {
//     record_type: 'api_key',
//     key: '',
//     value: '',
//   },
//   basic: {
//     record_type: 'basic',
//     username: '',
//     password: '',
//   },
//   bearer: {
//     record_type: 'bearer',
//     token: '',
//   },
//   oauth2: {
//     record_type: 'oauth2',
//     access_token_url: '',
//     auth_url: '',
//     client_id: '',
//     client_secret: '',
//     scope: '',
//     auth_parameter: '',
//   },
// };

function authDesc(recordType) {
  switch (recordType) {
    case 'basic':
      return 'Basic';
    case 'bearer':
      return 'Bearer';
    case 'oauth2':
      return 'OAuth2.0';
    case 'database':
      return 'Username/Password';
    default:
      return recordType;
  }
}

export default function AuthSubForm({
  connectionTemplate = null,
  editMode = false,
  form,
  skipUsingTemplate = false,
}) {
  return (
    <>
      <div className="mt-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Authentication
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Enter the authentication details for this application or database.
        </p>
      </div>
      <div className="mt-2 gap-y-6 gap-x-4">
        <div className="border rounded-md p-4 md:col-span-2">
          {
            skipUsingTemplate || (!!connectionTemplate && !connectionTemplate.authentication)
              ? (
                <div className="mb-6">
                  <RadioButtonGroup
                    label="Auth Strategy"
                    name="api.authentication.record_type"
                    valueOverride={form.watch('api.authentication.record_type')}
                    onChangeOverride={(v) => {
                      if (v === null) {
                        form.setValue('api.authentication.record_type', null);
                      } else {
                        form.setValue('api.authentication.record_type', v);
                      }
                    }}
                    options={[
                      { value: null, label: 'None' },
                      { value: 'api_key', label: 'API Key' },
                      { value: 'basic', label: 'Basic Auth' },
                      { value: 'bearer', label: 'Bearer Token' },
                      { value: 'oauth2', label: 'OAuth 2.0' },
                    ]}
                  />
                </div>
              )
              : (
                <span className="flex text-sm  text-gray-500">
                  <InformationCircleIcon className="text-indigo-500 h-5 w-5 mr-1 mb-4" />
                  <span className="flex">
                    <p className="font-medium mr-1 text-gray-700">{connectionTemplate?.name || form.watch('name')}</p>
                    {' '}
                    uses
                    {' '}
                    <p className="font-medium mx-1 text-gray-700">{authDesc(form.watch('api.authentication.record_type'))}</p>
                    {' '}
                    authentication.
                  </span>
                </span>
              )
          }
          <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
            { form.watch('api.authentication.record_type') === 'api_key' ? <ApiKeySubForm form={form} /> : null }
            { form.watch('api.authentication.record_type') === 'basic' ? <BasicSubForm form={form} /> : null }
            { form.watch('api.authentication.record_type') === 'bearer' ? <BearerSubForm form={form} /> : null }
            { form.watch('api.authentication.record_type') === 'oauth2' ? <OAuthTwoSubForm form={form} /> : null }
          </div>
          <ScopeInputWrapper form={form} connectionTemplate={connectionTemplate} editMode={editMode} />
        </div>
      </div>
    </>
  );
}

AuthSubForm.propTypes = {
  form: FormShape.isRequired,
  skipUsingTemplate: PropTypes.bool,
  connectionTemplate: PropTypes.oneOfType([ConnectionShape]),
  editMode: PropTypes.bool,
};
