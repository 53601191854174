/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

export default function LinkButton({
  buttonText = null,
  className = null,
  onClick = () => null,
  path = null,
}) {
  return (
    <button
      className={classNames(
        'text-indigo-700 background-transparent text-sm outline-none focus:outline-none ease-linear transition-all duration-150 cursor-pointer',
        className,
      )}
      type="button"
      onClick={onClick}
    >
      {
        path
          ? <Link className={className} to={path}>{buttonText}</Link>
          : buttonText
      }
    </button>
  );
}

LinkButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  path: PropTypes.string,
  buttonText: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
};
