import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { LinkIcon } from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import Loading from '../../../components/Loading';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import useClient from '../../../hooks/useClient';
import WorkflowShape from '../../../shapes/WorkflowShape';
import Form from './Form';
import Header from '../../../components/layout/PageHeader';
import SectionCard from '../../../components/layout/ShowPage/SectionCard';
import BodyWrapper from '../../../components/layout/ShowPage/BodyWrapper';
import useMe from '../../../context/CurrentUserContext';
import SliderForm from './SliderForm';
import PageWrapper from '../../../components/layout/PageWrapper';

export default function ClonePageWrapper() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const cloneId = searchParams.get('cloneId');

  const { data, isLoading } = useClient('workflows').get(id);
  const { data: clonedWorkflowData, isLoading: isLoadingClone } = useClient('workflows').get(cloneId, { enabled: !!cloneId });

  if (isLoading || (!!cloneId && isLoadingClone)) return <Loading />;
  if (!clonedWorkflowData) return <ClonePagePartOne workflow={data} />;
  return <ClonePagePartTwo templateId={data.id} cloneId={clonedWorkflowData.id} />;
}

function ClonePagePartOne({ workflow }) {
  const navigate = useNavigate();
  const form = useForm({ defaultValues: { ...workflow, name: `${workflow.name} CLONE` } });
  const queryClient = useQueryClient();

  const { isLoading: isSubmitting, mutate } = useClient('workflows').create({
    onSuccess: ({ id }) => {
      navigate(`/workflows/${workflow.id}/clone?cloneId=${id}`);
      queryClient.invalidateQueries('workflows');
    },
    onError: (error) => mapErrorsToForm(error.response.data, form),
  });

  const onSubmit = form.handleSubmit((w) => mutate({ workflow: { ...w, template: false } }));

  return (
    <PageWrapper>
      <Form
        form={form}
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        hideIsTemplateCheckbox
      />
    </PageWrapper>
  );
}

ClonePagePartOne.propTypes = {
  workflow: WorkflowShape.isRequired,
};

function ClonePagePartTwo({ templateId, cloneId }) {
  const [currentActionTemplate, setCurrentActionTemplate] = useState();
  const { allActions: actions } = useMe();
  const templateWorkflowActions = actions.filter((a) => a.workflow_id === templateId);
  const clonedWorkflowActions = actions.filter((a) => a.workflow_id === cloneId);

  return (
    <>
      <Header
        name="Workflow Clone Form"
        description="Create a workflow by starting with a template to clone."
        icon={<LinkIcon className="h-8 w-8 m-1 text-indigo-700" />}
      />
      <BodyWrapper>
        <SectionCard
          title="Clone Actions"
          subTitle={`You currently have ${clonedWorkflowActions.length} of ${templateWorkflowActions.length} actions cloned.`}
        >
          <SliderForm
            templateWorkflowActions={templateWorkflowActions}
            clonedWorkflowActions={clonedWorkflowActions}
            setCurrentActionTemplate={setCurrentActionTemplate}
            currentActionTemplate={currentActionTemplate}
            cloneId={cloneId}
          />
        </SectionCard>
      </BodyWrapper>
    </>
  );
}

ClonePagePartTwo.propTypes = {
  templateId: PropTypes.string.isRequired,
  cloneId: PropTypes.string.isRequired,
};
