import { DateTime, Duration } from 'luxon';

export function formatTime(string) {
  return DateTime
    .fromISO(string, { zone: 'UTC' })
    .toLocal()
    .toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
}

export function formatDate(string, dateFormat) {
  return DateTime
    .fromISO(string, { zone: 'UTC' })
    .toLocal()
    .toLocaleString(dateFormat || DateTime.DATE_SHORT);
}

export function formatDateTime(string) {
  return DateTime
    .fromISO(string, { zone: 'UTC' })
    .toLocal()
    .toLocaleString(DateTime.DATETIME_MED);
}

export function formatDateTimeWithSeconds(string) {
  return DateTime
    .fromISO(string, { zone: 'UTC' })
    .toLocal()
    .toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET);
}

export function timeAgo(string) {
  if (!string) return undefined;

  const then = DateTime.fromISO(string, { zone: 'UTC' }).toLocal();
  const now = DateTime.now();
  return now.diff(then);
}

export function formatTimeAgo(string) {
  const ago = (value) => `${value.toHuman({ floor: true })} ago`;
  const delta = timeAgo(string);

  if (!delta) return 'Never';

  if (delta < Duration.fromObject({ minutes: 1 })) return 'Just now';
  if (delta < Duration.fromObject({ hours: 1 })) return ago(delta.shiftTo('minutes'));
  if (delta < Duration.fromObject({ days: 1 })) return ago(delta.shiftTo('hours'));
  if (delta < Duration.fromObject({ months: 1 })) return ago(delta.shiftTo('days'));
  if (delta < Duration.fromObject({ years: 1 })) return ago(delta.shiftTo('months'));
  return ago(delta.shiftTo('years'));
}

export default {
  formatDateTime,
  formatTimeAgo,
};
