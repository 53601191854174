import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import { ChevronUpDownIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames/bind';
import { useController } from 'react-hook-form';
import FormShape from '../../../shapes/FormShape';
import { displayInputError } from '../../../helpers/FormHelpers';
import Option from './Option';

export default function ComboBox({
  options,
  label,
  name,
  form,
  disabled = false,
  placeholder = null,
  onChangeOverride = null,
  valueOverride = null,
  required = false,
  optionClass = null,
  helperText = null,
  optionOverride: OptionOverride = null,
  labelKey = 'label',
  valueKey = 'value',
  imageKey = null,
  preventSort = false,
}) {
  const [query, setQuery] = useState('');

  const {
    control,
    formState: { errors },
  } = form;

  const {
    field: {
      onChange, value: formValue,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  const sortedOptions = preventSort ? options : options.sort((a, b) => {
    const textA = a[labelKey].toUpperCase();
    const textB = b[labelKey].toUpperCase();
    // eslint-disable-next-line no-nested-ternary
    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
  });

  const filteredList = query === ''
    ? sortedOptions
    : sortedOptions.filter((opt) => opt[labelKey]
      .toLowerCase()
      .replace(/\s+/g, '')
      .includes(query.toLowerCase().replace(/\s+/g, '')));

  const value = valueOverride || formValue;
  // TODO: Do we even need this?
  const displayValue = options.find((option) => option[valueKey] === value);
  const onChangeFcn = onChangeOverride || ((v) => onChange(v[valueKey]));

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
      </label>
      <Combobox value={displayValue || {}} onChange={onChangeFcn} disabled={disabled}>
        {({ open }) => (
          <div className="relative mt-1 block w-full rounded-md border-gray-300 border focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
            <div
              className={
                classNames(
                  'relative w-full cursor-default overflow-hidden rounded-md bg-white text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm',
                  open ? 'outline-none ring-2 ring-white ring-offset-2 ring-offset-indigo-500' : null,
                )
              }
            >
              <Combobox.Button as="div">
                <Combobox.Input
                  className={classNames(
                    disabled ? 'bg-gray-100 text-gray-300' : 'text-gray-900',
                    'w-full border-none py-2 pl-3 pr-10 text-sm leading-5 focus:ring-indigo-500 focus:border-indigo-500',
                  )}
                  placeholder={placeholder || (label ? `${label}...` : null)}
                  displayValue={(opt) => (opt ? opt[labelKey] : '')}
                  onChange={(event) => setQuery(event.target.value)}
                  autoComplete="off"
                />
              </Combobox.Button>
              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => setQuery('')}
            >
              <Combobox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {filteredList.length === 0 && query !== '' ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    Nothing found.
                  </div>
                ) : (
                  filteredList.map((opt) => {
                    const DisplayOption = OptionOverride || Option;
                    return (
                      <DisplayOption
                        key={opt[valueKey]}
                        option={opt}
                        optionClass={optionClass}
                        labelKey={labelKey}
                        imagePath={opt[imageKey]}
                        valueKey={valueKey}
                      />
                    );
                  })
                )}
              </Combobox.Options>
            </Transition>
          </div>
        )}
      </Combobox>
      { typeof helperText === 'string' ? <p className="mt-2 text-sm text-gray-500">{helperText}</p> : helperText }
      <p className="mt-2 text-sm text-red-500">{displayInputError(errors, name)}</p>
    </>
  );
}

ComboBox.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  labelKey: PropTypes.string,
  valueKey: PropTypes.string,
  imageKey: PropTypes.string,
  form: FormShape.isRequired,
  required: PropTypes.bool,
  optionClass: PropTypes.string,
  onChangeOverride: PropTypes.func,
  valueOverride: PropTypes.string,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  optionOverride: PropTypes.func,
  preventSort: PropTypes.bool,
};
