/* eslint-disable max-len */
import React from 'react';
import ComboBox from '../../../components/forms/ComboBox';
import useMe from '../../../context/CurrentUserContext';
import IndexClientShape from '../../../shapes/IndexClientShape';

export default function FilterForm({ indexClient }) {
  const onCloseModal = () => indexClient.setFilterOpen(false);

  const { connections, actions } = useMe();

  return (
    <form onSubmit={indexClient.onSubmit}>
      <ComboBox
        label="Status"
        options={[
          { id: null, name: 'All' },
          { id: 'success', name: 'Success' },
          { id: 'error', name: 'Error' },
        ]}
        form={indexClient.filterForm}
        name="status"
        labelKey="name"
        valueKey="id"
      />
      <ComboBox
        label="Connection"
        options={connections}
        preventSort
        form={indexClient.filterForm}
        name="records.destination_connection_id"
        labelKey="name"
        valueKey="id"
        imageKey="icon_url"
      />
      <ComboBox
        label="Action"
        options={actions}
        preventSort
        form={indexClient.filterForm}
        name="records.action"
        labelKey="name"
        valueKey="id"
        imageKey="icon_url"
      />
      <div className="mt-6 flex justify-space-between">
        <button
          onClick={onCloseModal}
          type="button"
          className="mr-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
        >
          Cancel
        </button>
        <button
          type="submit"
          // disabled={isSubmitting}
          className="ml-2 w-6/12 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Filter
        </button>
      </div>
    </form>
  );
}

FilterForm.propTypes = {
  indexClient: IndexClientShape.isRequired,
};
