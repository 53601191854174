/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import EventShape from '../../../../../shapes/EventShape';
import TableRow from './TableRow';
import ConnectionShape from '../../../../../shapes/ConnectionShape';

export default function Table({ events, connection }) {
  return (
    <>
      {/* Projects list (only on smallest breakpoint) */}
      <div className="sm:hidden">
        <div className="px-4 sm:px-6">
          <h2 className="text-sm font-medium text-gray-900 mt-2">Requests</h2>
        </div>
        <ul className="mt-3 divide-y divide-gray-100 border-t border-gray-200">
          {events.map((event) => (
            <li key={event.id}>
              <button
                type="button"
                aria-label="Display Log"
                className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <span className="flex items-center space-x-3 truncate">
                  <span className="truncate text-xs font-medium leading-6">
                    <span className="font-heavy text-indigo-700 mr-1">
                      {event.topic}
                    </span>
                    <span className="truncate font-normal text-gray-500">
                      {/* {event.body.url} */}
                    </span>
                  </span>
                </span>
                <ChevronRightIcon
                  aria-hidden="true"
                  className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                />
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Projects table (small breakpoint and up) */}
      <div className="hidden sm:block">
        <div className="inline-block min-w-full border-b border-gray-200 align-middle p-4">
          <table className="min-w-full  border border-gray-200">
            <thead>
              <tr className="border-t border-gray-200">
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  <span className="lg:pl-2">Description</span>
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="hidden border-b border-gray-200 bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900 md:table-cell"
                >
                  Timestamp
                </th>
                <th
                  scope="col"
                  className="border-b border-gray-200 bg-gray-50 py-3 pr-6 text-right text-sm font-semibold text-gray-900"
                />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-100 bg-white">
              {events.map((event) => (
                <TableRow key={event.id} event={event} connection={connection} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>

  );
}

Table.propTypes = {
  events: PropTypes.arrayOf(EventShape).isRequired,
  connection: PropTypes.arrayOf(ConnectionShape).isRequired,
};
