/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import Input from '../../../../components/forms/InputLockable';
import FormShape from '../../../../shapes/FormShape';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import ComboBox from '../../../../components/forms/ComboBox';

export default function WebApp({
  form,
  connectionTemplate = null,
  editMode = false,
}) {
  return (
    <>
      <div className="mt-6">
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Connection
          {' '}
          Details
        </h3>
        <p className="mt-1 text-sm text-gray-500">
          Let's start with a Name, Icon, Docs, and Type.
        </p>
      </div>
      <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
        <div className="lg:col-span-1">
          <Input
            displayLockButton={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
            defaultLocked={!!connectionTemplate?.name || (editMode && !!form.watch('name'))}
            form={form}
            label="Name"
            name="name"
            placeholder="Name..."
          />
        </div>
        <div className="lg:col-span-1">
          <Input
            displayLockButton={!!connectionTemplate?.api?.url || (editMode && !!form.watch('connectionTemplate.api.url'))}
            defaultLocked={!!connectionTemplate?.api?.url || (editMode && !!form.watch('connectionTemplate.api.url'))}
            form={form}
            label="API URL"
            name="api.url"
            placeholder="Name..."
            helperText="Add the API URL to reach this Web application's API."
          />
        </div>
      </div>
      {
        !connectionTemplate
          ? (
            <div className="mt-2 grid grid-cols-1 gap-y-2 gap-x-4 xl:grid-cols-2">
              {
                !editMode
                  ? (
                    <div className="lg:col-span-1">
                      <ComboBox
                        required
                        form={form}
                        label="Type"
                        name="type"
                        options={[
                          { value: 'application', label: 'Web Application' },
                          { value: 'database', label: 'Database' },
                        ]}
                        helperText="Specify if this is a Web Application or DB."
                      />
                    </div>
                  )
                  : null
              }
              <div className="lg:col-span-1">
                <Input
                  form={form}
                  label="Icon URL"
                  name="icon_url"
                  placeholder="Icon URL..."
                />
              </div>
            </div>
          )
          : null
      }
    </>
  );
}

WebApp.propTypes = {
  form: FormShape.isRequired,
  connectionTemplate: ConnectionShape,
  editMode: PropTypes.bool,
};

