/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { DateTime } from 'luxon';
import FormShape from '../../shapes/FormShape';
import InputBase from './InputBase';

export default function DateInput({
  form,
  label,
  name,
  disabled = false,
  helperText = null,
  inputClass = 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
  max = null,
  min = null,
  onBlur = null,
  required = false,
  type = 'text',
}) {
  const decode = (v) => DateTime.fromISO(v).toISO();
  const set = { seconds: 0, milliseconds: 0 };
  const toISOSettings = {
    includeOffset: false,
    supressSeconds: true,
    suppressMilliseconds: true,
  };
  const encode = (v) => DateTime.fromISO(v).set(set).toISO(toISOSettings);

  return (
    <InputBase
      decode={decode}
      disabled={disabled}
      encode={encode}
      form={form}
      helperText={helperText}
      inputClass={inputClass}
      label={label}
      max={max}
      min={min}
      onBlur={onBlur}
      name={name}
      required={required}
      type={type}
    />
  );
}

DateInput.propTypes = {
  form: FormShape.isRequired,
  disabled: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  inputClass: PropTypes.string,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  max: PropTypes.string,
  min: PropTypes.string,
  onBlur: PropTypes.func,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['datetime-local', 'password']),
};
