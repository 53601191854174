/* eslint-disable react/require-default-props */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export default function Loading({
  children = null,
  isLoading = false,
  forIndex = false,
  className = null,
}) {
  if (children && !isLoading) return children;
  if (forIndex) {
    return (
      <div className="mt-8">
        <div className="flex">
          <svg fill="none" className="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <path
              clipRule="evenodd"
              d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
              fill="currentColor"
              fillRule="evenodd"
            />
          </svg>
          <p className="text-sm text-gray-700 mt-0.5 mb-1.5">Loading...</p>
        </div>
        <div role="status" className="p-4 space-y-4 divide-y divide-gray-200 rounded border border-gray-300 animate-pulse md:p-6">
          <div className="flex items-center justify-between">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full w-12" />
          </div>
          <div className="flex items-center justify-between pt-4">
            <div>
              <div className="h-2.5 bg-gray-300 rounded-full w-24 mb-2.5" />
              <div className="w-32 h-2 bg-gray-200 rounded-full" />
            </div>
            <div className="h-2.5 bg-gray-300 rounded-full w-12" />
          </div>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }
  return (
    <div className={classNames(className || 'flex items-center justify-center w-full my-28 h-96')}>
      <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
        <svg fill="none" className="w-6 h-6 animate-spin" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
          <path
            clipRule="evenodd"
            d="M15.165 8.53a.5.5 0 01-.404.58A7 7 0 1023 16a.5.5 0 011 0 8 8 0 11-9.416-7.874.5.5 0 01.58.404z"
            fill="currentColor"
            fillRule="evenodd"
          />
        </svg>
        <div>Loading ...</div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  children: PropTypes.element,
  isLoading: PropTypes.bool,
  forIndex: PropTypes.bool,
  className: PropTypes.string,
};
