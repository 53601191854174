/* eslint-disable react/require-default-props */
import { Dialog, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import classNames from 'classnames/bind';

export default function Modal({
  open = true,
  onClose = () => null,
  children = null,
  maxWidth = null,
  fullScreen = null,
}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className={
              classNames(
                'align-bottom sm:align-middle inline-block bg-white sm:my-8 overflow-hidden pt-5 pb-4 relative rounded-lg shadow-xl text-left transform transition-all',
                maxWidth || 'sm:max-w-xl sm:w-full',
                fullScreen ? '!my-0 w-full' : null,
              )
          }
            >
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  maxWidth: PropTypes.string,
  fullScreen: PropTypes.bool,
};
