/* eslint-disable react/require-default-props */
import PropTypes from 'prop-types';
import React from 'react';
import { useController } from 'react-hook-form';
import classNames from 'classnames';
import { displayInputError } from '../../helpers/FormHelpers';
import FormShape from '../../shapes/FormShape';

export default function InputBase({
  children = null,
  decode = (d) => d,
  disabled = false,
  disabledBgColor = null,
  encode = (e) => e,
  form,
  helperText = null,
  inputClass = 'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md',
  label,
  max = null,
  min = null,
  name,
  onBlur = null,
  placeholder = null,
  required = false,
  type = 'text',
}) {
  const {
    control,
    formState: { errors },
  } = form;

  const {
    field: {
      onChange, ref, value,
    },
  } = useController({
    name,
    control,
    rules: { required },
  });

  return (
    <>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <div className="mt-1 flex">
        <input
          type={type}
          disabled={disabled}
          className={classNames(
            inputClass,
            disabled ? (disabledBgColor || 'bg-gray-100') : null,
          )}
          onChange={({ target: { value: v }}) => onChange(decode(v))}
          onBlur={onBlur}
          ref={ref}
          value={encode(value) || ''}
          placeholder={placeholder}
          min={min}
          max={max}
        />
        {children}
      </div>
      <span className="mt-2 text-sm text-gray-500">{helperText}</span>
      <p className="mt-2 text-sm text-red-500">{displayInputError(errors, name)}</p>
    </>
  );
}

InputBase.propTypes = {
  decode: PropTypes.func,
  disabled: PropTypes.bool,
  encode: PropTypes.func,
  form: FormShape.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  max: PropTypes.string,
  min: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'password', 'datetime-local', 'date']),
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  placeholder: PropTypes.string,
  inputClass: PropTypes.string,
  children: PropTypes.element,
  disabledBgColor: PropTypes.string,

};
