import React from 'react';
import ConnectionShape from '../../../../../shapes/ConnectionShape';
import { lastFour } from '../../../../../helpers/ConnectionHelpers';

export default function Bearer({ connection }) {
  const { api: { authentication } } = connection;
  return (
    <div className="sm:col-span-1">
      <dt className="text-sm font-medium text-gray-500">Bearer Token</dt>
      <dd className="mt-1 text-sm text-gray-900">
        {lastFour(authentication.token)}
        <p className="text-xs text-gray-400">
          {lastFour(authentication.token).length}
          {' '}
          characters
        </p>
      </dd>
    </div>
  );
}

Bearer.propTypes = {
  connection: ConnectionShape.isRequired,
};
