import { renderTemplate } from './TemplateHelpers';

export function eventLabel(event, connection) {
  const { topic } = event;
  if (!connection.metadata?.events) return topic;

  const template = connection.metadata.events[event.topic];

  if (!template) return topic;

  return renderTemplate(template, { connection, event });
}

export function threadLabel(event, events, connection) {
  const { topic } = event;
  if (!connection.metadata?.events || !connection.metadata?.threads) return topic;

  const threadTopic = connection.metadata.threads[event.topic];
  if (!threadTopic) return topic;

  const templateEvent = (events || [event])
    .find((threadEvent) => threadEvent.topic === threadTopic);
  if (!templateEvent) return topic;

  return eventLabel(templateEvent, connection);
}

export const findConnection = ({ connection_id: connectionId }, connections) => (
  connections.find(({ id }) => id === connectionId)
);

export default {
  find: findConnection,
  eventLabel,
  threadLabel,
};
