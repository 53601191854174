/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import BaseBadge from './Badge';

export default function StatusBadge({ httpStatus = null }) {
  if (!httpStatus) return null;
  let color = 'gray';
  if (httpStatus >= 200 && httpStatus < 299) color = 'teal';
  if (httpStatus >= 400 && httpStatus < 499) color = 'yellow';
  if (httpStatus >= 500) color = 'red';

  return <BaseBadge color={color} message={httpStatus} />
}

StatusBadge.propTypes = {
  httpStatus: PropTypes.number.isRequired,
};
