/* eslint-disable react/button-has-type */
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames/bind';
import FormShape from '../../shapes/FormShape';

export default function Footer({
  onClickSubmit = () => null,
  submitButtonText,
  onClickPrevious = null,
  submitButtonDisabled,
  backButtonText,
  onBackButtonClick,
  submitButtonType = 'submit',
  isSubmitting = false,
  isSubmittingText = null,
  form = null,
}) {
  const errorKeys = Object.keys(form?.formState?.errors || {});

  return (
    <div className="mt-4">
      {
        errorKeys.length
          ? (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
              <strong className="font-bold">Holy smokes!</strong>
              <span className="block sm:inline ml-1">Something seriously bad happened.</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg
                  onClick={() => form.clearErrors()}
                  className="fill-current h-6 w-6 text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          ) : null
      }
      <div className="md:block hidden">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-3 pt-4">
            <svg
              width={18}
              height={18}
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.1279 3.34088C12.4574 3.67038 12.4574 4.20462 12.1279 4.53412L7.66199 9L12.1279 13.4659C12.4574 13.7954 12.4574 14.3296 12.1279 14.6591C11.7984 14.9886 11.2641 14.9886 10.9346 14.6591L5.87213 9.59662C5.54262 9.26712 5.54262 8.73288 5.87213 8.40338L10.9346 3.34088C11.2641 3.01137 11.7984 3.01137 12.1279 3.34088Z"
                fill="#4338CA"
              />
            </svg>
            <button type="button" onClick={onBackButtonClick} className="text-indigo-700 text-md">
              {backButtonText}
            </button>
          </div>
          <div className="flex gap-5 pt-4">
            {
            onClickPrevious
              ? (
                <button
                  type="button"
                  onClick={onClickPrevious}
                  className="mx-1 my-2 bg-white transition duration-150 ease-in-out focus:outline-none rounded text-indigo-700 border border-indigo-700 px-6 py-2 text-sm hover:bg-indigo-50"
                >
                  Previous Step
                </button>
              )
              : null
            }
            <button
              type={submitButtonType}
              disabled={submitButtonDisabled}
              onClick={onClickSubmit}
              className={classNames(
                submitButtonDisabled ? 'bg-gray-300' : 'bg-indigo-700 border border-indigo-700 hover:bg-indigo-600',
                'text-white mx-1 my-2 transition duration-150 ease-in-out rounded px-6 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2',
              )}
            >
              {isSubmitting && isSubmittingText ? isSubmittingText : submitButtonText}
            </button>
          </div>
        </div>
      </div>
      <div className="md:hidden block pt-4">
        <button
          type={submitButtonType}
          className="text-white border border-indigo-700 bg-indigo-700 px-6 py-3 rounded font-medium w-full hover:bg-indigo-600"
          disabled={submitButtonDisabled}
          onClick={onClickSubmit}
        >
          {isSubmitting && isSubmittingText ? isSubmittingText : submitButtonText}
        </button>
        {
          onClickPrevious
            ? (
              <button
                type="button"
                onClick={onClickPrevious}
                className="text-indigo-700 border border-indigo-700 px-6 py-3 rounded font-medium w-full mt-4 hover:bg-indigo-50"
              >
                Previous Step
              </button>
            ) : null
        }
        <div className="flex justify-center items-center gap-3 pt-4">
          <svg
            width={18}
            height={18}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.1279 3.34088C12.4574 3.67038 12.4574 4.20462 12.1279 4.53412L7.66199 9L12.1279 13.4659C12.4574 13.7954 12.4574 14.3296 12.1279 14.6591C11.7984 14.9886 11.2641 14.9886 10.9346 14.6591L5.87213 9.59662C5.54262 9.26712 5.54262 8.73288 5.87213 8.40338L10.9346 3.34088C11.2641 3.01137 11.7984 3.01137 12.1279 3.34088Z"
              fill="#4338CA"
            />
          </svg>
          <button type="button" onClick={onBackButtonClick} className="text-indigo-700 font-medium">
            {backButtonText}
          </button>
        </div>
      </div>
    </div>
  );
}

Footer.propTypes = {
  backButtonText: PropTypes.string.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  onClickPrevious: PropTypes.func,
  onClickSubmit: PropTypes.func,
  submitButtonText: PropTypes.string.isRequired,
  submitButtonType: PropTypes.string,
  submitButtonDisabled: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  isSubmittingText: PropTypes.string,
  form: FormShape,
};
