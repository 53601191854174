/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Footer from '../../../components/forms/Footer';
import Header from '../../../components/layout/PageHeader';
import { formatHeaders, connectionAvatar } from '../../../helpers/ConnectionHelpers';
import mapErrorsToForm from '../../../helpers/FormHelpers';
import ClientShape from '../../../shapes/ClientShape';
import ConnectionShape from '../../../shapes/ConnectionShape';
import AuthSubForm from '../Form/SubForms/AuthSubForm';
import WebAppSubForm from '../Form/SubForms/WebApp';

export default function ConnectionForm({ defaultValues, client, connectionTemplate }) {
  const navigate = useNavigate();

  const form = useForm({ defaultValues });

  const { isLoading: isSubmitting, mutate } = client.update(
    defaultValues.id,
    {
      onSuccess: ({ id }) => navigate(`/connections/${id}`),
      onError: (error) => mapErrorsToForm(error.response.data, form),
    },
  );

  const onSubmit = form.handleSubmit(
    (connection) => {
      const formattedData = formatHeaders(connection);
      return mutate({ connection: formattedData });
    },
  );

  return (
    <form onSubmit={onSubmit}>
      <Header
        name={`Edit ${form.watch('name')}`}
        description={`Edit your existing ${form.watch('name')} connection.`}
        avatar={connectionAvatar(form.watch(), { size: 'lg' })}
      />
      <div className="mx-auto max-w-3xl lg:max-w-7xl mt-10">
        <div className="w-full bg-white px-10 border border-gray-300 rounded mt-10">
          <WebAppSubForm form={form} connectionTemplate={connectionTemplate} editMode />
          <AuthSubForm
            form={form}
            connectionTemplate={connectionTemplate}
            skipUsingTemplate={false}
            editMode
          />
          <Footer
            backButtonText="Back to Connections"
            onBackButtonClick={() => navigate(-1)}
            submitButtonText="Submit"
            submitButtonDisabled={isSubmitting}
            submitButtonType="submit"
          />
        </div>
      </div>
    </form>
  );
}

ConnectionForm.propTypes = {
  defaultValues: ConnectionShape,
  client: ClientShape.isRequired,
  connectionTemplate: ConnectionShape,
};

ConnectionForm.defaultProps = {
  defaultValues: {},
  connectionTemplate: null,
};
