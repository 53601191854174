/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { BoltIcon, ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import activity from 'react-useanimations/lib/activity';
import _ from 'lodash';
import EventShape from '../../shapes/EventShape';
import LoadingSpinner from '../LoadingSpinner';
import LogShape from '../../shapes/LogShape';
import BaseBadge from './Badge';

export default function StatusIcon({ event, events = [], threadLogs }) {
  if (event.status === 'noop') {
    return (
      <BaseBadge
        message="No Match"
        color="gray"
      />
    );
  }

  const eventStatusList = events?.map((e) => e.status) || [];
  const hasEventPending = eventStatusList.includes('pending');

  if (hasEventPending) {
    return (
      <BaseBadge
        icon={<LoadingSpinner animation={activity} strokeColor="#5F9EA0" />}
        message="Pending"
        color="yellow"
      />
    );
  }

  const actionProcessedLogs = threadLogs.filter((l) => l.topic === 'action.processed');
  const failures = actionProcessedLogs.filter((l) => l.status === 'error');
  const failuresWithoutEventualSuccess = [];
  failures.forEach((l) => {
    const successResult = actionProcessedLogs.find((pl) => pl.records.action_id === l.records.action_id && pl.status === 'success');
    if (!successResult) failuresWithoutEventualSuccess.push(l.records.action_id);
    return null;
  });

  const uniqFailuresWithoutEventualSuccess = _.uniq(failuresWithoutEventualSuccess);
  if (uniqFailuresWithoutEventualSuccess.length) {
    return (
      <BaseBadge
        icon={<ExclamationTriangleIcon className="h-2 w-2 text-red-500" />}
        message="Resolved"
        color="red"
      />
    );
  }

  if (_.uniq(eventStatusList)[0] === 'resolved') {
    return (
      <BaseBadge
        icon={<BoltIcon className="h-2 w-2 text-teal-700" />}
        message="Resolved"
        color="teal"
      />
    );
  }

  return null;
}

StatusIcon.propTypes = {
  event: EventShape.isRequired,
  events: PropTypes.arrayOf(EventShape),
  threadLogs: PropTypes.arrayOf(LogShape).isRequired,
};
