import React from 'react';
import PropTypes from 'prop-types';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

export default function Header({
  name,
  description,
  buttons = [],
  icon: Icon = null,
  avatar: Avatar = null,
}) {
  const breadcrumbs = useBreadcrumbs();

  return (
    <>
      <div className="mx-auto max-w-3xl lg:flex lg:items-center lg:justify-between lg:space-x-5 lg:max-w-7xl">
        <p className="items-center text-indigo-700 text-sm hidden sm:block">
          {breadcrumbs.map(({ breadcrumb }, index) => (
            <span key={`breadcrumb-${breadcrumb.key}`}>
              {index !== 0 && <span className="mx-2">&gt;</span>}
              <span>{breadcrumb}</span>
            </span>
          ))}
        </p>
      </div>
      <div className="mx-auto max-w-3xl lg:flex lg:items-center lg:justify-between lg:space-x-5 lg:max-w-7xl">
        <div className="flex items-center">
          {
            (Icon || Avatar)
              ? (
                <div className="flex-shrink-0 mr-5">
                  <div className="relative">
                    {Icon
                      ? (
                        <div className="mt-1.5 mx-auto h-12 w-12 p-1 rounded bg-white border border-gray-300">
                          {typeof Icon === 'function' ? <Icon /> : Icon}
                        </div>
                      )
                      : null}
                    {Avatar ? <div className="mt-1">{Avatar}</div> : null}
                  </div>
                </div>
              )
              : null
}
          <div>
            <h1 className="text-2xl font-semibold text-gray-900">{name}</h1>
            <p className="text-sm font-medium text-gray-500">
              {description}
            </p>
          </div>
        </div>
        <div className="justify-stretch mt-6 flex flex-col-reverse space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-y-0 sm:space-x-3 sm:space-x-reverse lg:mt-0 lg:flex-row lg:space-x-3">
          {
            buttons.map((button) => (
              <button
                key={button.label}
                type="button"
                onClick={button.onClick}
                className="rounded flex space-x-2 px-3 min-w-fit h-8 font-normal text-sm leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
              >
                <button.icon className="h-5 w-4 mr-1 text-indigo-600" aria-hidden="true" />
                <p>{button.label}</p>
              </button>
            ))
          }
        </div>
      </div>

    </>
  );
}

const ButtonShape = PropTypes.shape({
  icon: PropTypes.elementType.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
});

Header.propTypes = {
  icon: PropTypes.element,
  avatar: PropTypes.element,
  name: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  buttons: PropTypes.arrayOf(ButtonShape),
};
