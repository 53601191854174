/* eslint-disable max-len */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlusCircleIcon, LinkIcon } from '@heroicons/react/24/outline';
import TableBody from './Body';
import TableHeader from './Header';
import MobileTableBody from './MobileBody';
import FilterBar from '../../../components/layout/FilterBar';
import PageHeader from '../../../components/layout/PageHeader';
import ItemList from '../../../components/ItemsList';
import useIndexFetchClient from '../../../hooks/useIndexFetchClient';
import SearchBar from '../../../components/layout/SearchBar';

const config = {
  clientName: 'connections',
  resourceName: 'Connection',
  description: 'A list of all connections in your account.',
  mobileTitle: 'Name',
  icon: <LinkIcon className="h-8 w-8 m-1 text-indigo-700" />,
  indexClientOptions: {
    searchParams: { template: false },
  },
};

export default function IndexPage() {
  // Fetch Index Page Base Data
  const indexClient = useIndexFetchClient(config);

  // Buttons for Top Bar
  const navigate = useNavigate();
  const buttons = [{ label: 'New Connection', icon: PlusCircleIcon, onClick: () => navigate('new') }];

  return (
    <div>
      <PageHeader
        description={config.description}
        name={`${config.resourceName} List`}
        buttons={buttons}
        icon={config.icon}
      />
      <FilterBar
        resource={`${config.resourceName}s`}
        open={indexClient.filterOpen}
        setOpen={indexClient.setFilterOpen}
      />
      <SearchBar
        name="search"
        label="Search Connections"
        indexClient={indexClient}
        placeholder="Search for Connections by Name..."
        statusTabConfig={{
          label: 'Filter by Type',
          filterKey: 'template',
          tabs: [
            { name: 'Connections', value: 'false' },
            { name: 'Templates', value: 'true' },
          ],
        }}
      />
      <ItemList
        indexClient={indexClient}
        body={<TableBody items={indexClient.data || []} />}
        mobileBody={<MobileTableBody mobileTitle={config.mobileTitle} items={indexClient.data || []} />}
        header={<TableHeader />}
      />
    </div>
  );
}
