/* eslint-disable max-len */
import React from 'react';
import { Cog8ToothIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import Tooltip from '../../../../components/Tooltip';

export default function WebhookTopicCard({ connection }) {
  const navigate = useNavigate();
  const { webhook } = connection;
  const topic = webhook?.topic;

  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300">
            <h2 id="applicant-information-title" className="text-lg flex justify-between font-medium leading-6 text-gray-900">
              <span className="flex">
                Topic Extraction
                <Tooltip
                  id="topic-header"
                  content="Strategy for extracting Event Topics from a Webhook."
                  tooltipPlace="right"
                  className="mt-1.5 ml-1"
                />
              </span>
              <button
                key="topic_config"
                type="button"
                onClick={() => navigate('webhook-topic-extraction')}
                className="rounded flex px-1 min-w-fit h-8 font-normal text-xs leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
              >
                <Cog8ToothIcon className="h-5 w-4 mr-1 text-indigo-600" aria-hidden="true" />
                <p>
                  {topic ? 'Update' : 'Setup' }
                  {' '}
                  Topic Extraction
                </p>
              </button>
            </h2>
          </div>
          <div className="mx-auto mt-6 max-w-5xl px-4 sm:px-6 lg:px-8 mb-6">
            {
              topic
                ? (
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        {topic.type === 'constant' ? 'Constant Topic' : 'Extracted From'}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {topic.type === 'constant' ? topic.name : topic.type}
                      </dd>
                    </div>
                    {
                  topic.type !== 'constant'
                    ? (
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Key Name
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {topic.name}
                        </dd>
                      </div>
                    )
                    : null
                }
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500 flex">
                        Extraction Regex
                        <Tooltip
                          id="extraction_regex"
                          content="An optional regex to extract this topic if combined with other data."
                          tooltipPlace="right"
                          className="mt-0.5 ml-1"
                        />
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {topic.pattern || 'N/A'}
                      </dd>
                    </div>
                  </dl>
                )
                : (
                  <div className="rounded-md bg-yellow-50 p-4 border border-yellow-600">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <ExclamationTriangleIcon aria-hidden="true" className="h-5 w-5 text-yellow-400" />
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm font-medium text-yellow-800">Setup needed</h3>
                        <div className="mt-2 text-sm text-yellow-700">
                          <p>
                            You must set up webhook extraction in order to start recieving webhooks for this connection.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            }

          </div>
        </div>
      </ErrorBoundary>
    </section>
  );
}

WebhookTopicCard.propTypes = {
  connection: ConnectionShape.isRequired,
};
