/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { DateTime } from 'luxon';
import ConnectionShape from '../../../../../shapes/ConnectionShape';
import useClient from '../../../../../hooks/useClient';
import Modal from '../../../../../components/Modal';
import ErrorBoundary from '../../../../../components/ErrorBoundary';
import Loading from '../../../../../components/Loading';
import LogDetailModalBody from '../../../../Action/ShowPage/LogDetailModalBody';
import Table from './Table';

export default function OrderListCard({ connection }) {
  const [currentLog, setCurrentLog] = useState(null);

  const [date, setDate] = useState(DateTime.now());
  const isoDate = date.toISODate();

  const startDate = date.startOf('day').toISO();
  const endDate = date.endOf('day').toISO();

  const { data: events, isLoading: isLoadingLogs } = useClient('events').list({
    connection_id: connection.id,
    // page_size: 100,
    topic: 'fulfillment_orders/fulfillment_request_submitted',
    start_date: startDate,
    end_date: endDate,
  });

  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              Orders by Day
            </h2>
            <div className="flex">
              <button
                type="button"
                aria-label="Previous Day"
                onClick={() => {
                  const prevDay = date.minus({ days: 1 });
                  setDate(prevDay);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                </svg>
              </button>
              <input
                className="h-7 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block text-xs border-gray-300 rounded-sm mx-2"
                type="date"
                value={isoDate}
                onChange={(e) => {
                  e.preventDefault();
                  setDate(DateTime.fromISO(e.target.value));
                }}
              />
              <button
                type="button"
                aria-label="Next Day"
                onClick={() => {
                  const nextDay = date.plus({ days: 1 });
                  setDate(nextDay);
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" className="size-4">
                  <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                </svg>
              </button>
            </div>
          </div>
          <Modal maxWidth="sm:max-w-5xl sm:w-full" open={!!currentLog} onClose={() => setCurrentLog(null)}>
            <LogDetailModalBody event={currentLog} onClose={() => setCurrentLog(null)} />
          </Modal>
          {
            isLoadingLogs
              ? (<Loading />)
              : (
                events.length
                  ? <Table events={events} connection={connection} />
                  : (
                    <p className="m-4 max-w-2xl text-sm text-gray-500">
                      There are currently no API Request for this connection.
                    </p>
                  )
              )
          }
        </div>
      </ErrorBoundary>
    </section>
  );
}

OrderListCard.propTypes = {
  connection: ConnectionShape.isRequired,
};
