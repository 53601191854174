// import LogRocket from 'logrocket';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import qs from 'qs';
import ActionsIndexPage from './screens/Action/IndexPage';
import ActionCreate from './screens/Action/CreatePage';
import ActionEdit from './screens/Action/EditPage';
import ActionSuperEditPage from './screens/Action/SuperEditPage';
import ActionTestPage from './screens/Action/Test';
import App from './App';
import useMe from './context/CurrentUserContext';
import useAuth from './context/AuthContext';
import Events from './screens/Event/IndexPage';
import Event from './screens/Event/ShowPage';
import EventCreate from './screens/Event/CreatePage';
import EventEditPage from './screens/Event/EditPage';
import Login from './screens/Auth/Login';
import ConnectionCreatePage from './screens/Connection/CreatePage';
import ConnectionEditPage from './screens/Connection/EditPage';
import ConnectionIndexPage from './screens/Connection/IndexPage';
import ConnectionShowPage from './screens/Connection/ShowPage';
import ConnectionClonePage from './screens/Connection/Clone';
import ConnectionSuperEditPage from './screens/Connection/SuperEditPage';
import ConnectionWebhookValidation from './screens/Connection/Webhook/Verifcation';
import ConnectionWebhookTopicExtraction from './screens/Connection/Webhook/TopicExtraction';
import NotFound from './screens/NotFound';
import Signup from './screens/Signup';
import Modal from './components/Modal';
import ActionDetailPage from './screens/Action/ShowPage';
import OAuthRedirect from './screens/Connection/OAuthRedirect';
import RequestIndexPage from './screens/Request/IndexPage';
import WorkflowCreatePage from './screens/Workflow/CreatePage';
import WorkflowEditPage from './screens/Workflow/EditPage';
import WorkflowIndexPage from './screens/Workflow/IndexPage';
import WorkflowShowPage from './screens/Workflow/ShowPage';
import WorkflowClonePage from './screens/Workflow/Clone';
import ThreadsIndexPage from './screens/Thread/IndexPage';
import ThreadsShowPage from './screens/Thread/ShowPage';
import { enableSentry } from './config';
import PrivacyPolicy from './screens/PrivacyPolicy';
import EntityEditPage from './screens/Entity/EditPage';
// import { enableLogRocket } from './config';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function Private({ children }) {
  const location = useLocation();
  const { currentUser } = useMe();
  const { accessToken } = useAuth();

  if (!currentUser || !accessToken) {
    return (
      <Navigate
        to="/login"
        replace
        state={{ path: location.pathname }}
      />
    );
  }

  // if (process.env.NODE_ENV === 'production' || enableLogRocket) {
  //   useEffect(() => {
  //     LogRocket.identify(currentUser.id, {
  //       email: currentUser.email,
  //       name: currentUser.name,
  //     });
  //   }, []);
  // }

  if (process.env.NODE_ENV === 'production' || enableSentry) {
    useEffect(() => {
      Sentry.setUser({
        id: currentUser.id,
        email: currentUser.email,
        username: currentUser.name,
      });
    }, []);
  }

  return children;
}

Private.propTypes = {
  children: PropTypes.element.isRequired,
};

function Public({ children }) {
  const { currentUser } = useMe();
  const { accessToken } = useAuth();
  const location = useLocation();
  const search = qs.parse(location.search.substring(1));

  // IF we have a code and state params, we've gotten here via a redirect for our oauth flow
  if (!!search.code && !!search.state) return (children);

  // If we do not have a currentuser and accessToken, then navigate
  // to our Login or Password Screen
  if (!(currentUser && accessToken)) return (children);

  // If we made it this far and have a currentUser or accessToken, try to load data
  return (<Navigate to="/" replace />);
}

Public.propTypes = {
  children: PropTypes.element.isRequired,
};

export default function AppRouter() {
  const location = useLocation();
  const { state } = location;
  const { currentEntity } = useMe();
  return (
    <>
      <SentryRoutes location={state?.backgroundLocation || location}>
        <Route path="/authorize-connections" element={<Public><OAuthRedirect /></Public>} />
        <Route path="/login" element={<Public><Login /></Public>} />
        <Route path="/signup" element={<Public><Signup /></Public>} />
        <Route path="/privacy-policy" element={<Public><PrivacyPolicy /></Public>} />
        <Route path="/" element={<Private><App /></Private>}>
          <Route index element={<Navigate replace to="/threads" />} />
          <Route path="dashboard" element={<Navigate replace to="/threads" />} />
          <Route path="dashboard" element={<Navigate replace to="/threads" />} />
          <Route path="entity/edit" element={<EntityEditPage />} />

          <Route path="events">
            <Route index element={<Events />} />
            <Route exact path="new" element={<EventCreate />} />
            <Route path=":id" element={<Event />} />
            <Route path=":id/edit" element={<EventEditPage />} />
          </Route>
          <Route path="threads">
            <Route index element={<ThreadsIndexPage />} />
            <Route path=":id" element={<ThreadsShowPage />} />
            {/* <Route exact path="new" element={<EventCreate />} />
            <Route path=":id/edit" element={<EventEditPage />} /> */}
          </Route>
          <Route path="connections">
            <Route index element={<ConnectionIndexPage />} />
            <Route exact path="new" element={<ConnectionCreatePage />} />
            <Route path=":id" element={<ConnectionShowPage />} />
            <Route path=":id/edit" element={<ConnectionEditPage />} />
            <Route path=":id/super-edit" element={<ConnectionSuperEditPage />} />
            <Route path=":id/clone" element={<ConnectionClonePage />} />
            <Route path=":id/webhook-validation" element={<ConnectionWebhookValidation />} />
            <Route path=":id/webhook-topic-extraction" element={<ConnectionWebhookTopicExtraction />} />
          </Route>
          <Route path="actions">
            <Route index element={<ActionsIndexPage />} />
            <Route exact path="new" element={<ActionCreate />} />
            <Route path=":id" element={<ActionDetailPage />} />
            <Route exact path=":id/edit" element={<ActionEdit />} />
            <Route exact path=":id/test" element={<ActionTestPage />} />
            <Route path=":id/super-edit" element={<ActionSuperEditPage />} />
          </Route>
          <Route path="requests">
            <Route index element={<RequestIndexPage />} />
          </Route>
          <Route path="workflows">
            <Route index element={<WorkflowIndexPage />} />
            <Route exact path="new" element={<WorkflowCreatePage />} />
            <Route path=":id" element={<WorkflowShowPage />} />
            <Route exact path=":id/edit" element={<WorkflowEditPage />} />
            <Route path=":id/clone" element={<WorkflowClonePage />} />
          </Route>
          {
            currentEntity.metadata?.dynamic_index_pages?.map((p) => (
              <Route key={`path-${p.path}`} path={p.path}>
                <Route
                  index
                  element={(
                    <ThreadsIndexPage
                      title={p.name}
                      staticFilters={p.filters}
                    />
                  )}
                />
                <Route path=":id" element={<ThreadsShowPage />} />
              </Route>
            ))
          }
          <Route path="*" element={<NotFound />} />
        </Route>
      </SentryRoutes>
      {/* Show the modal when a `backgroundLocation` is set */}
      {(state?.backgroundLocation) && (
        <SentryRoutes>
          <Route exact path="/events/new" element={<Private><Modal><EventCreate /></Modal></Private>} />
          <Route exact path="/connections/:id/edit" element={<Private><Modal><ConnectionEditPage /></Modal></Private>} />
          <Route exact path="/workflows/:id/edit" element={<Private><Modal><WorkflowEditPage /></Modal></Private>} />
        </SentryRoutes>
      )}
    </>
  );
}
