/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { formatTime } from '../../../../../helpers/DateHelpers';
import EventShape from '../../../../../shapes/EventShape';
import useClient from '../../../../../hooks/useClient';
import StatusBadge from '../../../../../components/Badges/StatusBadge';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import { threadLabel } from '../../../../../helpers/EventHelpers';
import ConnectionShape from '../../../../../shapes/ConnectionShape';

export default function TableRow({ event, connection }) {
  const { data: events, isInitialLoading } = useClient('events').list(
    {
      thread: event.thread,
      page_size: 50,
    },
    { enabled: event.status !== 'noop' },
  );

  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoadingLogs, setIsLoadingLogs] = useState(true);

  const eventsIds = events?.map((e) => e.id) || [];
  const { data, meta } = useClient('logs').list(
    { records: { event_id: [...[event.id], ...eventsIds] }, page_size: 100 },
    { enabled: !!events?.length },
  );

  useEffect(() => {
    setLogs([...logs, ...(data || [])]);
    if (meta?.page && meta.page < meta.total_pages) {
      setPage(page + 1);
    } else {
      setIsLoadingLogs(false);
    }
  }, [meta?.page]);

  return (
    <tr key={event.id}>
      <td className="w-full max-w-0 whitespace-nowrap px-6 py-3 text-xs font-medium text-gray-900">
        <div className="flex flex-wrap items-center space-x-3 lg:pl-2">
          <span className="truncate flex">
            {threadLabel(event, events || [], connection)}
            { isInitialLoading ? <LoadingSpinner forIndex /> : null }
          </span>
        </div>
      </td>
      <td className="px-6 py-3 text-xs font-medium text-gray-500">
        {
          events?.length || event.status === 'noop'
            ? <StatusBadge event={event} events={events} threadLogs={logs} />
            : null
        }
        {/* <RequestResponseStatusBadge httpStatus={event.body.http_status} /> */}
      </td>
      <td className="hidden whitespace-nowrap px-6 py-3 text-right text-xs text-gray-500 md:table-cell">
        {formatTime(event.inserted_at)}
      </td>
      <td className="whitespace-nowrap px-6 py-3 text-right text-sm font-medium">
        {/* <LinkButton className="text-xs" buttonText="Details" onClick={() => setCurrentLog(event)} /> */}
      </td>
    </tr>
  );
}

TableRow.propTypes = {
  event: EventShape.isRequired,
  connection: ConnectionShape.isRequired,
};
