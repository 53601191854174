/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PlusCircleIcon, AdjustmentsVerticalIcon } from '@heroicons/react/24/outline';
import { useForm } from 'react-hook-form';
import { diff } from 'deep-object-diff';
import useMe from '../context/CurrentUserContext'
import { fromSearchParams, toSearchParams } from '../helpers/ObjectHelpers';
import useClient from './useClient';

export default function useIndexFetchClient(config) {
  const { clientName, indexClientOptions: options = {} } = config;

  // Index Page Filter State
  const [filterOpen, setFilterOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  // Index Page Data Fetching
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObj = fromSearchParams(searchParams);
  const [requestFailed, setRequestFailed] = useState(false);
  const onError = () => setRequestFailed(true);

  const defaultSearchParams = options.searchParams || {};
  const optionLatentSearchParams = options.latentSearchParams || {};

  // Create our filter for the index query
  // Start with the default params from the config
  // Override with our latent/hidden search params like template: true
  // Override with URL search params last
  const search = {
    ...defaultSearchParams,
    ...optionLatentSearchParams,
    ...searchParamsObj,
  };

  const {
    data,
    meta,
    isLoading,
    isRefetching,
  } = useClient(clientName).list(search, { onError });

  // Create an instance of filter form using our default values
  const filterForm = useForm({ defaultValues: { ...defaultSearchParams, ...searchParamsObj } });

  const onChangeFilter = (filter) => {
    const { page, ...filterSansPage } = filter;
    const difference = diff(searchParamsObj, filterSansPage);
    const diffKeys = Object.keys(difference);

    // Any keys in the filter form or search bar that are not represented in
    // config.searchParams would send us back to page 1
    const filteredDiffkeys = diffKeys.filter((k) => filterSansPage[k] !== searchParamsObj[k]);

    // If any fields are different, we don't put page back in and rever to page 1
    const filterWithPage = filteredDiffkeys.length ? filterSansPage : { ...filterSansPage, page };

    setFilterOpen(false);
    setSearchParams(toSearchParams(filterWithPage));
  };
  const onSubmit = filterForm.handleSubmit(onChangeFilter);

  // Set our initial search params in the URL at the very end
  // Take the defaults from our config and merge the params from the URL to override if needed
  useEffect(() => {
    setSearchParams(toSearchParams({ ...defaultSearchParams, ...searchParamsObj }));
  }, []);

  // // Reset page if we change entities
  // const { currentEntity } = useMe();
  // useEffect(() => {
  //   setSearchParams(toSearchParams(defaultSearchParams));
  // }, [currentEntity.id]);

  return {
    data,
    expandedRows,
    setExpandedRows,
    filterForm,
    filterOpen,
    setFilterOpen,
    isLoading,
    isRefetching,
    initialLatentSearchParams: optionLatentSearchParams,
    meta,
    onError,
    onSubmit,
    requestFailed,
    urlParamsObj: searchParamsObj,
  };
}

export function useIndexButtons(resourceName, indexClient) {
  const navigate = useNavigate();
  return [
    { label: 'Filter', icon: AdjustmentsVerticalIcon, onClick: () => indexClient.setFilterOpen(true) },
    { label: `New ${resourceName}`, icon: PlusCircleIcon, onClick: () => navigate('new') },
  ];
}
