import React from 'react';
import { LockClosedIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import ErrorBoundary from '../../../../components/ErrorBoundary';
import ConnectionShape from '../../../../shapes/ConnectionShape';
import { lastFour } from '../../../../helpers/ConnectionHelpers';
import Tooltip from '../../../../components/Tooltip';
import LinkButton from '../../../../components/buttons/LinkButton';

export default function WebhookSecurity({ connection }) {
  const navigate = useNavigate();
  if (!connection.webhook?.security) return null;
  return (
    <section aria-labelledby="action-information-title">
      <ErrorBoundary>
        <div className="bg-white border border-gray-300 rounded">
          <div className="px-4 py-2 border-b border-gray-300 flex justify-between">
            <h2 id="applicant-information-title" className="text-lg font-medium leading-6 text-gray-900">
              <span className="flex">
                Security
                <Tooltip
                  id="signature"
                  content="Verify that webhooks are actually coming from this Connection."
                  tooltipPlace="right"
                  className="mt-1.5 ml-1"
                />
              </span>
            </h2>
            <button
              key="signature"
              type="button"
              onClick={() => navigate('webhook-validation')}
              className="rounded flex px-1 min-w-fit h-8 font-normal text-xs leading-3 border border-gray-300 text-gray-800 bg-white focus:outline-none focus:bg-gray-200 hover:bg-gray-200 duration-150 justify-center items-center"
            >
              <LockClosedIcon className="h-5 w-4 mr-1 text-indigo-600" aria-hidden="true" />
              <p>Update Webhook Security</p>
            </button>
          </div>
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 m-4">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Webhook Secret</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {lastFour(connection.webhook?.secret)}
                <p className="text-xs text-gray-400">
                  {lastFour(connection.webhook?.secret)?.length}
                  {' '}
                  characters
                </p>
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500 flex">
                Signature Extraction
                <Tooltip
                  id="signature_extraction"
                  content="Strategy for extracting validation Signatures from a Webhook."
                  tooltipPlace="right"
                  className="mt-0.5 ml-1"
                />
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {connection.webhook?.signature
                  ? (
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-3 p-4 border border-gray-300 rounded-md">
                      <div className="col-span-1">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          Extracted From
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {connection.webhook?.signature?.type}
                        </dd>
                      </div>
                      <div className="col-span-1">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          {connection.webhook?.signature?.type === 'constant' ? 'Static Signature' : 'Key Name'}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {connection.webhook?.signature?.name}
                        </dd>
                      </div>
                      {
                        connection.webhook?.signature?.type !== 'constant'
                          ? (
                            <div className="col-span-1">
                              <dt className="text-sm font-medium text-gray-500 flex">
                                Extraction Regex
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {connection.webhook?.signature?.pattern || 'N/A'}
                              </dd>
                            </div>
                          )
                          : null
                      }
                    </dl>
                  )
                  : (
                    <span>
                      Webhook signature extraction not configured. Webhook source cannot be verified without extracting a signature?.
                      {' '}
                      <LinkButton buttonText="Set up Topic Extraction." path="webhook-topic-extraction" />
                    </span>
                  )}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500 flex">
                Comparison String Template
                <Tooltip
                  id="comparison_body"
                  content={(
                    <>
                      <p>A combination of the Webhook Payload, Request Method, Request URL, Client Secret, and a Timestamp.</p>
                      <p>Values are extracted from each webhook and formed into a comparison string with the shape defined here.</p>
                      <p>This string will be hashed and then encoded before being compared to the signature?.</p>
                    </>
                )}
                  tooltipPlace="right"
                  className="mt-0.5 ml-1"
                />
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {connection.webhook?.body}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500 flex">
                Timestamp Extraction
                <Tooltip
                  id="extraction_timestap"
                  content="Strategy for extracting Timestamps from a Webhook."
                  tooltipPlace="right"
                  className="mt-0.5 ml-1"
                />
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {connection.webhook?.timestamp
                  ? (
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-3 p-4 border border-gray-300 rounded-md">
                      <div className="col-span-1">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          Extracted From
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {connection.webhook?.timestamp.type}
                        </dd>
                      </div>
                      <div className="col-span-1">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          {connection.webhook?.timestamp.type === 'constant' ? 'Static Signature' : 'Key Name'}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {connection.webhook?.timestamp.name}
                        </dd>
                      </div>
                      {
                        connection.webhook?.timestamp.type !== 'constant'
                          ? (
                            <div className="col-span-1">
                              <dt className="text-sm font-medium text-gray-500 flex">
                                Extraction Regex
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {connection.webhook?.timestamp.pattern || 'N/A'}
                              </dd>
                            </div>
                          )
                          : null
                      }
                    </dl>
                  )
                  : (
                    'N/A'
                  )}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500 flex">
                Hashing and Encoding Functions
                <Tooltip
                  id="hasing"
                  content="The comparison string that is built from each webhook will be hashed and then encoded using these methods."
                  tooltipPlace="right"
                  className="mt-0.5 ml-1"
                />
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {connection.webhook?.security
                  ? (
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2 p-4 border border-gray-300 rounded-md">
                      <div className="col-span-1">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          Encoding
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {connection.webhook?.security.encoding}
                        </dd>
                      </div>
                      <div className="col-span-1">
                        <dt className="text-sm font-medium text-gray-500 flex">
                          Hashing
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {connection.webhook?.security.method}
                        </dd>
                      </div>
                    </dl>
                  )
                  : (
                    'N/A'
                  )}
              </dd>
            </div>
          </dl>
        </div>
      </ErrorBoundary>
    </section>
  );
}

WebhookSecurity.propTypes = {
  connection: ConnectionShape.isRequired,
};
