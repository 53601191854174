/* eslint-disable react/require-default-props */
import React from 'react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import DateInput from '../forms/DateInput';
import IndexClientShape from '../../shapes/IndexClientShape';

const now = DateTime.now();
const startOfDay = now.startOf('day').toISO();
const startOfWeek = now.startOf('week').toISO();
const startOfMonth = now.startOf('month').toISO();
const startOfSevenDays = now.minus({ days: 6 }).startOf('day').toISO();
const startOfFourteenDays = now.minus({ days: 13 }).startOf('day').toISO();
const endValue = now.plus({ days: 1 }).startOf('day').toISO();

const timeOptions = [
  { label: 'Today', startValue: startOfDay, endValue },
  { label: 'This Week', startValue: startOfWeek, endValue },
  { label: 'This Month', startValue: startOfMonth, endValue },
];

const timeOptions2 = [
  { label: 'Last 7 days', startValue: startOfSevenDays, endValue },
  { label: 'Last 14 days', startValue: startOfFourteenDays, endValue },
];

export default function DateFilter({ indexClient }) {
  const { onSubmit } = indexClient;
  return (
    <div className="w-full mt-6 xl:mt-0 bg-gray-100 border border-gray-300 rounded p-3">
      <div className="sm:flex justify-between items-center">
        <div>
          <DateInput
            inputClass="h-8 mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            form={indexClient.filterForm}
            type="datetime-local"
            label="Start Date"
            name="start_date"
            onBlur={onSubmit}
          />
        </div>
        <div>
          <DateInput
            inputClass="h-8 mt-2 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
            form={indexClient.filterForm}
            type="datetime-local"
            label="End Date"
            name="end_date"
            onBlur={onSubmit}
          />
        </div>
      </div>
      <div className="sm:flex justify-between items-center">
        <nav className="-mb-px flex space-x-8 mt-3">
          {timeOptions2.map((tab) => (
            <button
              type="button"
              key={tab.label}
              onClick={() => {
                indexClient.filterForm.setValue('start_date', tab.startValue);
                indexClient.filterForm.setValue('end_date', tab.endValue);
                indexClient.onSubmit();
              }}
              className={classNames(
                tab.startValue === indexClient.filterForm.watch('start_date') && tab.endValue === indexClient.filterForm.watch('end_date')
                  ? 'rounded-md bg-indigo-50 px-2 py-0.5 text-sm font-medium text-indigo-700 border border-indigo-700'
                  : 'rounded-sm text-sm font-medium text-gray-500 hover:text-gray-700',
              )}
              aria-current={tab.value === 'adsf' ? 'page' : undefined}
            >
              {tab.label}
            </button>
          ))}
        </nav>
        <nav className="-mb-px flex space-x-8 mt-3">
          {timeOptions.map((tab) => (
            <button
              type="button"
              key={tab.label}
              onClick={() => {
                indexClient.filterForm.setValue('start_date', tab.startValue);
                indexClient.filterForm.setValue('end_date', tab.endValue);
                indexClient.onSubmit();
              }}
              className={classNames(
                tab.startValue === indexClient.filterForm.watch('start_date') && tab.endValue === indexClient.filterForm.watch('end_date')
                  ? 'rounded-md bg-indigo-50 px-2 py-0.5 text-sm font-medium text-indigo-700 border border-indigo-700'
                  : 'rounded-sm text-sm font-medium text-gray-500 hover:text-gray-700',
              )}
              aria-current={tab.value === 'adsf' ? 'page' : undefined}
            >
              {tab.label}
            </button>
          ))}
        </nav>
      </div>
    </div>
  );
}

DateFilter.propTypes = {
  indexClient: IndexClientShape.isRequired,
};
